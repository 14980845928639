// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".updatePasswordForm-module__root--Kb3Uh{width:100%;max-width:350px}.updatePasswordForm-module__row--JszEm+.updatePasswordForm-module__row--JszEm{margin-top:16px}.updatePasswordForm-module__forgotLink--mKBxC{font-size:12px}.updatePasswordForm-module__guidanceText--Inv4O p{margin:0}.updatePasswordForm-module__guidanceText--Inv4O p:not(:first-child){margin-top:16px}", "",{"version":3,"sources":["webpack://./jsapp/js/account/security/password/updatePasswordForm.module.scss","webpack://./jsapp/scss/sizes.scss"],"names":[],"mappings":"AAEA,wCACE,UAAA,CACA,eCqCK,CDlCP,8EACE,eCWI,CDRN,8CACE,cCGI,CDEN,kDACE,QAAA,CAEA,oEACE,eCFE","sourcesContent":["@use 'scss/sizes';\n\n.root {\n  width: 100%;\n  max-width: sizes.$x350;\n}\n\n.row + .row {\n  margin-top: sizes.$x16;\n}\n\n.forgotLink {\n  font-size: sizes.$x12;\n}\n\n// Note: we allow all markdown-generated HTML inside this, so we might need to\n// add some more styles here.\n.guidanceText :global p {\n  margin: 0;\n\n  &:not(:first-child) {\n    margin-top: sizes.$x16;\n  }\n}\n","/*\n * A temporary-ish file for defining all the sizes we use in the app to avoid\n * creating unnecessary new ones. Ultimately we would like to have a small\n * nice palette and start merging similar ones. This will also help us find\n * how many of each of them we use.\n */\n\n$x1: 1px;\n$x2: 2px;\n$x3: 3px;\n$x4: 4px;\n$x5: 5px;\n$x6: 6px;\n$x8: 8px;\n$x10: 10px;\n$x12: 12px;\n$x13: 13px;\n$x14: 14px;\n$x15: 15px;\n$x16: 16px;\n$x18: 18px;\n$x20: 20px;\n$x21: 21px;\n$x24: 24px;\n$x28: 28px;\n$x30: 30px;\n$x32: 32px;\n$x36: 36px;\n$x38: 38px;\n$x40: 40px;\n$x48: 48px;\n$x50: 50px;\n$x60: 60px;\n$x80: 80px;\n$x100: 100px;\n$x120: 120px;\n$x140: 140px;\n$x150: 150px;\n$x180: 180px;\n$x200: 200px;\n$x300: 300px;\n$x350: 350px;\n$x400: 400px;\n$x600: 600px;\n$x800: 800px;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "updatePasswordForm-module__root--Kb3Uh",
	"row": "updatePasswordForm-module__row--JszEm",
	"forgotLink": "updatePasswordForm-module__forgotLink--mKBxC",
	"guidanceText": "updatePasswordForm-module__guidanceText--Inv4O"
};
export default ___CSS_LOADER_EXPORT___;
